import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { save, load } from "redux-localstorage-simple";
import { Provider } from "react-redux";
import { fetchProducts } from "./redux/actions/productActions";
import { fetchCustomItems } from "./redux/actions/customItemsActions"; 
import { fetchCustomerStories } from "./redux/actions/customerStories"; 
import { fetchHomepageFeatures } from "./redux/actions/homepageFeatureActions"; 
import rootReducer from "./redux/reducers/rootReducer";
import products from "./data/products.json";
import App from "./App";
import "./assets/scss/style.scss";
import "./assets/scss/_override_style1.scss";
import * as serviceWorker from "./serviceWorker";

import axios from 'axios';

import { composeWithDevTools } from "redux-devtools-extension";

const store = createStore(
  rootReducer,
  load({namespace:'ejr'}),
  composeWithDevTools(applyMiddleware(thunk, save({namespace:'ejr'})))
);


axios
    .get('/api/homepageFeatures/active',
      // { headers: { Authorization: `${account.token}` }}
    )
    .then(function (response) {
        // console.log({response})
        store.dispatch(fetchHomepageFeatures(response.data));
    })
    .catch(function (error) {
        console.log({error})
    });
axios
    .get('/api/products/web',
      // { headers: { Authorization: `${account.token}` }}
    )
    .then(function (response) {
        // console.log({webRes:response})
        let filteredData = response.data.filter((dd)=>{return dd.shopIds.length == 0 || dd.shopIds.indexOf('ejr')>=0})
        store.dispatch(fetchProducts(filteredData));
    })
    .catch(function (error) {
        console.log({error})
    });
axios
    .get('/api/customItems',
      // { headers: { Authorization: `${account.token}` }}
    )
    .then(function (response) {
        // console.log({response})
        // store.dispatch(fetchCustomItems(response.data.customItems));
        store.dispatch(fetchCustomItems(response.data));
    })
    .catch(function (error) {
        console.log({error})
    });
axios
    .get('/api/customerStories',
      // { headers: { Authorization: `${account.token}` }}
    )
    .then(function (response) {
        // console.log({response})
        store.dispatch(fetchCustomerStories(response.data));
    })
    .catch(function (error) {
        console.log({error})
    });
    
// fetch products from json file
// store.dispatch(fetchProducts(products));

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
